












































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import moment from 'moment';

@Component({})
export default class ReceiptPrint extends Vue {
  receiptNumber = ''
  orderNumber = ''
  orderDate = ''
  printDate = ''

  totalPrice = 0
  includeTax = true
  paymentMethodName = ''
  pointPrice = 0
  couponPrice = 0
  requestPrice = 0
  taxReducedPrice = 0
  taxStandardPrice = 0

  shopName = ''
  shopUrl = ''
  shopEmail = ''
  faxNumber = ''
  phoneNumber = ''
  zipCode = ''
  address = ''

  orderer = ''
  proviso = ''
  coupon = true
  point = true
  dateType = 'orderDate'

  mounted() {
    this.receiptNumber = this.$route.params.id
    this.orderer = this.$route.params.orderer
    this.proviso = `${this.$route.params.proviso}`
    this.coupon = this.$route.params.coupon === 'true'
    this.point = this.$route.params.point === 'true'
    this.dateType = `${this.$route.params.dateType}`

    this.proviso = "商品代として"
    if(this.$route.params.proviso) {
      this.proviso = `${this.$route.params.proviso}`
    }

    axios.get(
      `/data/${this.receiptNumber}.json`
    )
    .then(res => {
      this.orderNumber = res.data.orderNumber
      this.orderDate = res.data.orderDate
      this.orderer = !this.$route.params.orderer ? res.data.orderer : this.$route.params.orderer

      this.totalPrice = res.data.totalPrice
      this.includeTax = res.data.includeTax
      this.paymentMethodName = res.data.paymentMethodName
      this.pointPrice = res.data.point
      this.couponPrice = res.data.coupon
      this.requestPrice = this.totalPrice

      if(this.point) {
        this.requestPrice = this.requestPrice - this.pointPrice
      }

      if(this.coupon) {
        this.requestPrice = this.requestPrice - this.couponPrice
      }
      
      this.taxReducedPrice = res.data.taxReducedPrice
      this.taxStandardPrice = res.data.taxStandardPrice
      
      this.shopName = res.data.shopName
      this.shopUrl = res.data.shopUrl
      this.shopEmail = res.data.shopEmail
      this.faxNumber = res.data.faxNumber
      this.phoneNumber = res.data.phoneNumber
      this.zipCode = res.data.zipCode
      this.address = res.data.address

      this.printDate = moment(res.headers['date']).format("YYYY/MM/DD")
    }).catch(err => {
      this.$router.push({
        name: 'Home',
        params: {
          id: this.$route.params.id,
          notFound: 'true',
        }
      })
    })
  }

  onClickPrint() {
    window.print()
  }

  onClickCancel() {
    this.$router.push({
      name: 'Receipt',
      params: {
        id: this.receiptNumber,
        orderer: this.orderer,
        proviso: this.proviso,
        coupon: `${this.coupon}`,
        point: `${this.point}`,
        dateType: this.dateType
      }});
  }
}
